// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-nl-js": () => import("./../src/pages/about.nl.js" /* webpackChunkName: "component---src-pages-about-nl-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-nl-js": () => import("./../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

